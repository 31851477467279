import React from "react"

import {Link,graphql} from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"

const AboutPage = props => (
    <Layout>
        <SEO title="Simone Montalto" keywords=""/>

        <div style={{display:"flex", alignItems: "center", justifyContent: "center", flexDirection: "row"}} >
            <Img fixed={props.data.logo.childImageSharp.fixed} alt="Simone Montalto"/>
        </div>
        
        <div className={"page-header"}>
            <h2 style={{fontSize: `3.5rem`}}>Simone Montalto</h2>
            <p style={{fontSize: `1.4rem`}}>Tech enthusiast and Indie Dev<br />iOS & Mac developer. Swift + Objective-C<br />I like to make apps to keep track of all aspects of our lives<br /><br />Creator of <u><Link to="https://booktrack.app" title={"Book Tracker" } target="_blank" rel="noopener noreferrer">Book Tracker</Link></u>, <u><Link to="https://countdown-widget.app" title={"Countdown Widget" } target="_blank" rel="noopener noreferrer">Countdown Widget</Link></u>, <u><Link to="https://habittrack.app" title={"Habit Tracker" } target="_blank" rel="noopener noreferrer">Habit Tracker</Link></u>, <u><Link to="https://movietrack.app" title={"Movie Tracker" } target="_blank" rel="noopener noreferrer">Movie Tracker</Link></u> and <u><Link to="https://musictracker.app" title={"Music Tracker" } target="_blank" rel="noopener noreferrer">Music Tracker</Link></u><br />❤️</p>
        </div>
    </Layout>
)

export default AboutPage

export const data = graphql`  
query LandingPageQuery {    
    logo: file(relativePath: { eq: "simone-montalto-profile-picture.png" }) {
        childImageSharp {
          fixed(width: 250, height: 250) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }` 