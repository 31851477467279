/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import {StaticQuery, graphql, Link} from "gatsby"

import Header from "./header"
import "../../static/styles/main.scss"
import logo from "../../static/images/logo-icon.png"
import iconX from "../../static/images/icon-x.svg"
import iconMastodon from "../../static/images/icon-mastodon.svg"
import iconLinkedin from "../../static/images/icon-linkedin.svg"
import iconGitHub from "../../static/images/icon-github.svg"

const Layout = ({children}) => (
    <StaticQuery query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `} render={data => (
        <>
            <Header siteTitle={data.site.siteMetadata.title}/>
            <main>{children}</main>
            <footer>
                <div className={"container"}>
                    <div className={"row"}>
                        <div className={"col-5"}>
                            <div className={"widget__item"}>
                                <div className={"logo"}>
                                    <Link to="/" title={"Simone Montalto"}>
                                        <img alt={"Simone Montalto Logo"} src={logo} style={{width: '44px'}}/>
                                    </Link>
                                </div>

                                <div className={"about"}>
                                    <p><span style={{fontWeight: 'bold'}}>Simone Montalto</span><br/>Computer Science and Engineering<br/>@Politecnico di Milano (Msc)<br/>Indie Developer<br/>iOS & Mac developer. Swift + Objective-C</p>
                                </div>
                            </div>
                        </div>

                        <div className={"col-2"}>
                            <div className={"widget__item"}>
                                <div className={"links"}>
                                    <h4>SECTIONS</h4>
                                    <ul>
                                        <li><Link to="/blog" title={"Blog"}>Blog</Link></li>
                                        <li><Link to="/contact" title={"Contact Us"}>Contact</Link></li>
                                        <li><Link to="/privacy" title={"Privacy Policy"}>Privacy</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className={"col-2"}>
                            <div className={"widget__item"}>
                                <div className={"links"}>
                                    <h4>PROJECTS</h4>
                                    <ul>
                                        <li><Link to="https://booktrack.app" title={"Book Tracker" } target="_blank" rel="noopener noreferrer">Book Tracker</Link></li>
                                        <li><Link to="https://countdown-widget.app" title={"Countdown Widget" } target="_blank" rel="noopener noreferrer">Countdown Widget</Link></li>
                                        <li><Link to="https://habittrack.app" title={"Habit Tracker" } target="_blank" rel="noopener noreferrer">Habit Tracker</Link></li>
                                        <li><Link to="https://movietrack.app" title={"Movie Tracker" } target="_blank" rel="noopener noreferrer">Movie Tracker</Link></li>
                                        <li><Link to="https://musictracker.app" title={"Music Tracker" } target="_blank" rel="noopener noreferrer">Music Tracker</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className={"col-3"}>
                            <div className={"widget__item"}>
                                <div className={"social"}>
                                    <a href="https://x.com/SimoneMontalto" target={"_blank"} rel={"noreferrer"} title={"X"}><img alt={"X"} src={iconX}/></a>
                                    <a href="https://mastodon.social/@simonemontalto" target={"_blank"} rel={"noreferrer"} title={"Mastodon"}><img alt={"Mastodon"} src={iconMastodon}/></a>
                                    <a href="https://www.linkedin.com/in/simone-montalto-01a673b5/" target={"_blank"} rel={"noreferrer"} title={"LinkedIn"}><img alt={"LinkedIn"} src={iconLinkedin}/></a>
                                    <a href="https://github.com/SimoneMontalto/" target={"_blank"} rel={"noreferrer"} title={"GitHub"}><img alt={"GitHub"} src={iconGitHub}/></a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={"copyright"}>
                        <p>Copyright {new Date().getFullYear()}, {` `} <a href="https://simonemontalto.com" title={"Simone Montalto"}>Simone Montalto</a>. All rights reserved.</p>
                    </div>
                </div>
            </footer>
        </>
    )}/>
)

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
